<template>
  <page-content id="new-event-forms-view">
    <v-card title="Godziny pracy">
      <validation-observer v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(validateForm)">
          <v-card-title>{{ $t("Nowa zmiana") }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <users-select-2 :user="parent" :multiple="false" @user-selected="parentSelected" :label="parentLabel"
                  :users="parentQuery" :hint="parentHint" />
              </v-col>
              <v-col cols="8">
                <users-select-2 :user="users" @user-selected="userSelected" :label="childrenLabel" :users="childrenQuery"
                  :hint="childrenHint" />
              </v-col>
              <v-col cols="4">
                <VueCtkDateTimePicker v-bind="$attrs" v-model="started" no-clear-button color="#f64e65"
                  hint="Rozpoczęcie zmiany" label="Wybierz datę i godzinę" format="YYYY-MM-DD HH:mm"
                  formatted="dddd, DD MMMM YYYY HH:mm" />
              </v-col>
              <v-col cols="2">
                <v-select dense v-model="workingTime" outlined :items="times">
                  <template v-slot:selection="{ item }">
                    {{ workingTimeDisplay(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ workingTimeDisplay(item) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <app-card-actions>
              <cancel-btn @click="$router.back()" />
              <apply-btn :loading="saving" type="submit" />
            </app-card-actions>
          </v-card-text>
        </form>
      </validation-observer>
    </v-card>
  </page-content>
</template>

<script>
import UserService from "@/services/user.service";
import { addHours } from "date-fns";
// import siteService from '@/services/site.service'
// import eventService from '@/services/event.service'

export default {
  name: "FormWorkingTime",
  beforeMount() {
    console.log("hash", this.$route.hash);
    this.started = this.$options.filters.localDateTime(new Date())
    if (this.$route.hash == "#patrol") {
      this.parentLabel = this.$t("user.patrol");
      this.parentQuery = (value) => {
        return UserService.fetchPatrols(value).then((data) => data.results);
      };
      this.childrenLabel = this.$t("user.patrolman");
      this.childrenHint = "group: _patrolman";
      this.childrenQuery = (value) => {
        console.log("fetchPatrollers", value);
        return UserService.fetchPatrollers(value).then((data) => data.results);
      };
    } else if (this.$route.hash == "#dispatcher") {
      this.parentLabel = this.$t("user.dispatcher");
      this.parentQuery = (value) =>
        UserService.fetchDispatchers(value).then((data) => data.results);
      this.childrenLabel = this.$t("user.assistant.dispatcher");
      this.childrenHint = "group: _assistant_dispatcher";
      this.childrenQuery = (value) =>
        UserService.fetchAssistents(value).then((data) => data.results);
    }
  },
  data: () => ({
    parent: null,
    users: null,
    parentLabel: "",
    parentHint: null,
    parentQuery: new Promise((resolve) => resolve([])),
    childrenLabel: "",
    childrenHint: null,
    childrenQuery: new Promise((resolve) => resolve([])),
    started: null,
    saving: false,
    workingTime: "12",
    times: ["4", "8", "12", "14"],
  }),
  methods: {
    siteSelected(site) {
      this.site = site;
      if (this.autocommit) {
        console.log("site", this.site);
        this.event.site = site;
      }
    },
    workingTimeDisplay(value) {
      return value + " h";
    },
    parentSelected(user) {
      this.parent = user;
    },
    userSelected(users) {
      this.users = users;
    },
    validateForm() {
      let all = [];
      const started = new Date(this.started);
      if (this.parent && this.users) {
        for (const user of this.users) {
          all.push(
            UserService.createWorkingTime({
              user_id: user.id,
              started_at: started.toISOString(),
              finished_at: addHours(
                started,
                this.workingTime
              ).toISOString(),
              parent_id: this.parent.id,
            })
          );
        }
      }
      if (this.parent && this.$route.hash == "#dispatcher") {
        all.push(
          UserService.createWorkingTime({
            user_id: this.parent.id,
            started_at: started.toISOString(),
            finished_at: addHours(started, this.workingTime).toISOString(),
          })
        );
      }
      Promise.all(all).then(() => this.$router.back());
    },
  },
};
</script>
